<template>
  <div class="container">
    <NavBar />
    <div class="page_title">Tuya Api</div>
    <div v-show="!loaded">Loading ...</div>
    <div v-show="loaded">
      <div class="info_section info_buttons">
        <v-btn class="tuya_btn" variant="text" @click="reinitialise()">
          Refresh</v-btn
        >
      </div>
      <div v-if="accessToken != '' && loaded">
        <div class="info_section">Device Information</div>
        <div class="info_section">
          <div class="device_table">
            <v-data-table fixed-header :headers="fields" :items="deviceDetails">
              <template v-slot:item.deviceActive="{ item }">
                <v-btn
                  class="action_btn"
                  variant="text"
                  :style="{
                    backgroundColor: this.getButtonColor(
                      item.columns.deviceActive
                    ),
                  }"
                  @click="active(item)"
                  >{{ this.getButtonActive(item.columns.deviceActive) }}</v-btn
                >
              </template>
              <template v-slot:item.deviceStatus="{ item }">
                <v-btn
                  class="action_btn"
                  variant="text"
                  :style="{
                    backgroundColor: this.getButtonColor(
                      item.columns.deviceStatus
                    ),
                  }"
                  @click="device(item)"
                  >{{ this.getButtonStatus(item.columns.deviceStatus) }}
                </v-btn>
              </template>
              <template #bottom></template>
            </v-data-table>
          </div>
        </div>

        <div class="info_section">
          <div class="device_table">
            <v-data-table
              fixed-header
              :headers="fieldsPlugs"
              :items="deviceDetailsPlugs"
            >
              <template v-slot:item.deviceActive="{ item }">
                <v-btn
                  class="action_btn"
                  variant="text"
                  :style="{
                    backgroundColor: this.getButtonColor(
                      item.columns.deviceActive
                    ),
                  }"
                  @click="active(item)"
                  >{{ this.getButtonActive(item.columns.deviceActive) }}</v-btn
                >
              </template>
              <template v-slot:item.deviceStatus="{ item }">
                <v-btn
                  class="action_btn"
                  variant="text"
                  :style="{
                    backgroundColor: this.getButtonColor(
                      item.columns.deviceStatus
                    ),
                  }"
                  @click="device(item)"
                  >{{ this.getButtonStatus(item.columns.deviceStatus) }}
                </v-btn>
              </template>
              <template #bottom></template>
            </v-data-table>
          </div>
        </div>
      </div>
      <div class="info_section">API: {{ backendurl }}</div>
      <div class="info_section">Access token: {{ accessToken }}</div>
      <div class="info_section">Status: {{ currentStatus }}</div>
      <div class="info_section">Current Tariff: {{ currentTariff }}</div>

      <div class="info_section">
        <v-btn class="tuya_btn" variant="text" @click="cron()">
          Test Cron Job</v-btn
        >
      </div>

      <div class="info_section response_section">
        Response:
        <pre>{{ message }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./Navbar/Navbar.vue";
import Axios from "axios";
import { VDataTable } from "vuetify/labs/VDataTable";

export default {
  name: "Tuya_API",
  components: { NavBar, VDataTable },
  data() {
    return {
      currentTariff: null,
      loaded: false,
      authKey: "",
      currentStatus: "",
      deviceInfos: [],
      tempNumbers: [],
      backendurl: process.env.VUE_APP_API,
      temp_current: null,
      temp_set: null,
      temp_mode: null,
      deviceStatus: null,
      apiCode: "switch",
      apiValue: true,
      method: "",
      commands: null,
      fields: [
        { key: "deviceName", title: "Device Name", sortable: true },
        { key: "deviceID", title: "ID", sortable: false, align: " d-none" },
        { key: "deviceType", title: "Type", sortable: false, align: " d-none" },

        { key: "deviceActive", title: "Active", sortable: false },
        { key: "deviceStatus", title: "Status", sortable: false },
        {
          key: "deviceCurrentTemp",
          title: "Current Temp",
          sortable: false,
        },
        { key: "deviceSetTemp", title: "Set Temp", sortable: false },
        { key: "deviceSavedTemp", title: "DB Temp", sortable: false },
        { key: "deviceMode", title: "Mode", sortable: false },
      ],
      fieldsPlugs: [
        { key: "deviceName", title: "Device Name", sortable: true },
        { key: "deviceID", title: "ID", sortable: false, align: " d-none" },
        { key: "deviceType", title: "Type", sortable: false, align: " d-none" },

        { key: "deviceActive", title: "Active", sortable: false },
        { key: "deviceStatus", title: "Status", sortable: false },
        { key: "deviceOther", title: "Next on", sortable: false },
        { key: "deviceOther2", title: "-", sortable: false },
        { key: "deviceOther3", title: "-", sortable: false },
      ],
      stringToSignUrl: "",
      deviceID: "",
      deviceDetails: [],
      deviceDetailsPlugs: [],
      deviceIDs: [],
      deviceIDsPlugs: [],
      accessToken: "",
      status: null,
      headers: [],
      action: "",
      easy_sign: "",
      message: null,
      baseUrl: "https://openapi.tuyaeu.com",
      tuyaApi: null,
      url: "",
    };
  },
  created() {
    for (var i = 5; i < 25; i++) {
      this.tempNumbers.push(i);
    }
  },
  mounted() {
    this.$store.commit("setAccessToken", "");
    this.getDevices();
  },
  methods: {
    getDevices() {
      this.message = null;
      this.deviceInfos = [];
      this.deviceDetails = [];
      this.deviceDetailsPlugs = [];
      this.currentStatus = "Getting devices";
      // this.action = "auth";
      var postdata = {
        module: "settings",
        action: "getDevices",
        key: this.$store.state.authKey,
        detail: {},
      };

      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          this.deviceIDs = res.data;
          this.auth();
        }
      });
    },

    async auth() {
      this.message = null;
      this.deviceInfos = [];
      this.deviceDetails = [];
      this.deviceDetailsPlugs = [];
      this.currentStatus = "Getting access token";
      this.action = "auth";
      this.stringToSignUrl = "/v1.0/token?grant_type=1";
      var postdata = {
        module: "tuya",
        action: "auth",
        key: this.$store.state.authKey,
        detail: {
          baseUrl: this.baseUrl,
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          stringToSignUrl: this.stringToSignUrl,
        },
      };

      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          if (res.data.success === false) {
            this.message = res.data.message;
            return;
          }
          this.$store.commit("setAccessToken", res.data.result.access_token);
          this.accessToken = res.data.result.access_token;
          this.initialise();
        }
      });
    },
    reinitialise() {
      this.loaded = false;
      this.message = null;
      this.deviceInfos = [];
      this.deviceDetails = [];
      this.deviceDetailsPlugs = [];
      this.auth();
    },
    initialise() {
      for (var i = 0; i < this.deviceIDs.length; i++) {
        const newItem = {
          deviceName: this.deviceIDs[i].text,
          deviceID: this.deviceIDs[i].value,
          deviceType: this.deviceIDs[i].type,
          deviceActive: "Wait",
          deviceStatus: "Wait",
          deviceCurrentTemp: 0,
          deviceSetTemp: 0,
          deviceSavedTemp: 0,
          deviceMode: "",
        };
        if (this.deviceIDs[i].type != "plug") {
          this.deviceDetails.push(newItem);
        }
        if (this.deviceIDs[i].type == "plug") {
          this.deviceDetailsPlugs.push(newItem);
        }
      }
      this.currentStatus = "Getting device info ";
      this.getTuyaData();
    },

    async getTuyaData() {
      this.deviceInfos = [];
      for (var i = 0; i < this.deviceIDs.length; i++) {
        const deviceID = this.deviceIDs[i].value;
        const deviceType = this.deviceIDs[i].type;
        const stringToSignUrl = "/v2.0/cloud/thing/{device_id}".replace(
          "{device_id}",
          deviceID
        );
        await this.makeCall("get_info", stringToSignUrl, deviceID, deviceType);
      }
      this.message = this.deviceInfos;
      this.currentStatus = "Idle";
    },

    async makeCall(action, stringToSignUrl, deviceID, deviceType) {
      if (this.accessToken == "" || this.accessToken === null) {
        this.message = "No access token";
        return;
      }
      var postdata = {
        module: "tuya",
        key: this.$store.state.authKey,
        detail: {
          access_token: this.accessToken,
          deviceID: deviceID,
          deviceType: deviceType,
          baseUrl: this.baseUrl,
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          stringToSignUrl: stringToSignUrl,
        },
      };

      postdata.action = action;
      if (action == "commands") {
        postdata.detail.commands = this.commands;
        this.currentStatus = "Sending command";
      }

      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        this.loaded = true;
        if (res.data) {
          if (res.data.success === false) {
            this.message = res.data;
            return;
          }
          this.currentTariff = res.data.tariff;
          const deviceID = res.data.deviceID;
          var device = "";
          var index = this.deviceDetails.findIndex(
            (e) => e.deviceID == deviceID
          );
          if (index == -1) {
            device = "plug";
            index = this.deviceDetailsPlugs.findIndex(
              (e) => e.deviceID == deviceID
            );
          }

          if (index != -1) {
            if (action == "get_info") {
              this.device_set_values(device, index, res.data);
              this.deviceInfos.push(res.data);
            }
            if (action == "commands") {
              this.device_set_values(device, index, res.data.newState);
              this.message = res.data;
              this.currentStatus = "Idle";
            }
          }
        }
      });
    },

    device_set_values(device, index, data) {
      if (data["online"] === false) {
        if (device != "plug") {
          this.deviceDetails[index].deviceStatus = "Offline";
          this.deviceDetails[index].deviceActive = "Offline";
        }
        if (device == "plug") {
          this.deviceDetailsPlugs[index].deviceStatus = "Offline";
          this.deviceDetailsPlugs[index].deviceActive = "Offline";
        }
      } else {
        if (device != "plug") {
          this.deviceDetails[index].deviceStatus = data.switch ? "On" : "Off";
          this.deviceDetails[index].deviceCurrentTemp = data["currentTemp"];
          if (data["setTemp"] != "no_change") {
            this.deviceDetails[index].deviceSetTemp = data["setTemp"];
          }
          this.deviceDetails[index].deviceMode = data["mode"];
          this.deviceDetails[index].deviceSavedTemp = data["savedTemp"];
          this.deviceDetails[index].deviceActive = data.active;
        }
        if (device == "plug") {
          this.deviceDetailsPlugs[index].deviceStatus = data.switch
            ? "On"
            : "Off";
          this.deviceDetailsPlugs[index].deviceSavedTemp = data["savedTemp"];
          this.deviceDetailsPlugs[index].deviceActive = data.active;
        }
      }
    },

    device(e) {
      const deviceID = e.columns.deviceID;
      const deviceStatus = e.columns.deviceStatus;
      if (deviceStatus !== "Offline") {
        e.columns.deviceStatus = "Wait";
        const deviceType = e.columns.deviceType;
        const stringToSignUrl =
          "/v2.0/cloud/thing/{device_id}/shadow/properties/issue".replace(
            "{device_id}",
            deviceID
          );

        if (deviceStatus == "On") {
          this.commands = { Switch: "false" };
        } else {
          this.commands = { Switch: "true" };
        }
        this.makeCall("commands", stringToSignUrl, deviceID, deviceType);
      }
    },
    getButtonActive(status) {
      if (status == "y") return "Active";
      if (status == "n") return "Off";
      if (status == "Offline") return "Offline";
      if (status == "Wait") return "Wait";
      return "-";
    },
    getButtonStatus(status) {
      if (status == "On") return "Turn Off";
      if (status == "Off") return "Turn On";
      if (status == "Wait") return "Wait";
      if (status == "Offline") return "Offline";
      return "-";
    },
    getButtonColor(status) {
      if (status == "y") return "lightgreen";
      if (status == "n") return "yellow";
      if (status == "On") return "lightgreen";
      if (status == "Off") return "yellow";
      if (status == "Wait") return "lightgray";
      if (status == "Offline") return "lightblue";
      return "lightgray";
    },
    active(e) {
      const deviceID = e.columns.deviceID;
      const deviceActive = e.columns.deviceActive;
      if (deviceActive !== "Offline") {
        const type = e.columns.deviceType;
        this.message = "";
        this.currentStatus = "Updating settings";
        var postdata = {
          module: "settings",
          action: "updateActive",
          key: this.$store.state.authKey,
          detail: {
            deviceID: deviceID,
            deviceActive: deviceActive,
            type: type,
          },
        };
        Axios.post(this.backendurl, postdata, this.headers).then((res) => {
          if (res.data) {
            var device = "";
            var index = this.deviceDetails.findIndex(
              (e) => e.deviceID == res.data.deviceID
            );
            if (index == -1) {
              device = "plug";
              index = this.deviceDetailsPlugs.findIndex(
                (e) => e.deviceID == deviceID
              );
            }
            if (index != -1) {
              if (device == "") {
                this.deviceDetails[index].deviceActive = res.data.active;
              }
              if (device == "plug") {
                this.deviceDetailsPlugs[index].deviceActive = res.data.active;
              }
            }
            this.message = res.data;
          }
          this.currentStatus = "Idle";
        });
      }
    },

    cron() {
      this.message = "";
      this.currentStatus = "Running cron";
      var postdata = {
        module: "tuya_cron",
        action: "cron",
        key: this.$store.state.authKey,
        detail: {},
      };
      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          for (var i = 0; i < res.data.infoRequestResult.length; i++) {
            const result = res.data.infoRequestResult[i]["newState"];
            const index = this.deviceDetails.findIndex(
              (e) => e.deviceID == result.deviceID
            );
            if (index != -1) {
              this.deviceDetails[index].deviceStatus =
                result.switch === true ? "On" : "Off";
              this.deviceDetails[index].deviceSetTemp = result.setTemp;
              this.deviceDetails[index].deviceSaveTemp = result.saveTemp;
              this.deviceDetails[index].deviceMode = result.mode;
              this.deviceDetails[index].deviceCurrentTemp = result.currentTemp;
            } else {
              const index = this.deviceDetailsPlugs.findIndex(
                (e) => e.deviceID == result.deviceID
              );
              if (index != -1) {
                this.deviceDetailsPlugs[index].deviceStatus =
                  result.switch === true ? "On" : "Off";
              }
            }
          }
          this.currentTariff = res.data.tariff;
          this.message = res.data;
        }

        this.currentStatus = "Idle";
      });
    },
  },
};
</script>

<style scoped>
.page_title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.container {
  margin: 20px;
}
.tuya_btn {
  background-color: lightgray;
}
.action_btn {
  background-color: lightgray;
  width: 100px;
}
.action_btn_active {
  background-color: lightgoldenrodyellow;
}
.page_item {
  margin-top: 20px;
}
.page_item_buttons {
  display: flex;
  gap: 20px;
}
.device_table {
  border: 1px grey solid;
}
.info_section {
  margin-top: 10px;
}
.response_section {
  border: 1px solid gray;
  overflow-x: auto;
}
.info_buttons {
  display: flex;
  gap: 20px;
}
</style>
