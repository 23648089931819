<template>
  <Line id="my-chart-id" :options="chartOptions" :data="chartData" />
</template>

<script>
// import { Bar } from "vue-chartjs";
import { Line } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Line },
  props: ["data"],

  data() {
    return {
      chartData: {
        labels: [],
        datasets: [{ data: [] }],
      },
      deviceData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
        },
        scales: {
          x: {
            ticks: {
              callback: function (value) {
                return this.getLabelForValue(value);
              },
              maxRotation: 90, // Rotate the labels
              minRotation: 90,
            },
          },
        },
      },
    };
  },
  watch: {
    data() {
      this.setData(this.data);
    },
  },
  methods: {
    setData(data) {
      if (data.length == 0) {
        return;
      }
      if (data.logData.length > 0) {
        const logData = data.logData;
        let labels = [];
        let tempCurrentData = [];
        let tempSetData = [];
        let tariffDataSet = [];
        for (let i = 0; i < logData.length; i++) {
          labels.push(logData[i].logTime);
          tempCurrentData.push(logData[i].logTempCurrent);
          tariffDataSet.push(logData[i].logTariff);
          if (logData[i].logState == "On") {
            tempSetData.push(logData[i].logTempSet);
          } else {
            tempSetData.push(0);
          }
        }
        this.chartData = {
          labels: labels,
          datasets: [
            {
              label: "Current Temp",
              backgroundColor: "#eb3d17",
              data: tempCurrentData,
            },
            {
              label: "Active",
              backgroundColor: "#24b410",
              data: tempSetData,
            },
            {
              label: "Tariff",
              backgroundColor: "#101ab4",
              data: tariffDataSet,
            },
          ],
        };
      }
    },
  },
};
</script>
